import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isBrowser } from 'utils/browser';
import Layout from 'components/Layout';
import Text from 'components/Text';
import tw from 'twin.macro';
import Col from 'components/Col';
import Row from 'components/Row';
import Logo from 'components/Logo';
import { FormHolder, BaseForm } from 'components/Form';
import { ActionButton } from 'components/Button';

const Avatar = tw.img`rounded-full border border-gray-100 shadow-sm h-20`;

const Profile = () => {
  if (!isBrowser) return null;
  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  if (!isLoading && isAuthenticated === false) {
    loginWithRedirect();
    return null;
  }
  const [loadingCompanyInformation, setloadingCompanyInformation] = useState(false);
  const onUpdateCompanyInformation = () => {};
  return (
    <Layout>
      <Row spacing="sm" tw="items-center my-10">
        <Avatar src={user?.picture} />
        <h1 className="text-4xl font-bold text-indigo-900">Hi {user?.nickname}</h1>
      </Row>
      <Col tw="w-3/5">
        <Text label="Company Information" large spacing="sm" />
        <FormHolder onSubmit={onUpdateCompanyInformation}>
          {({ methods }) => (
            <Col>
              <BaseForm
                pages={[
                  {
                    direction: 'col',
                    elements: [
                      {
                        isRequired: true,
                        name: 'email',
                        placeholder: 'Email Address',
                        defaultValue: '',
                        label: 'Email',
                        full: true
                      },
                      {
                        isRequired: true,
                        name: 'password',
                        placeholder: 'Password',
                        defaultValue: '',
                        label: 'Password',
                        full: true
                      }
                    ]
                  }
                ]}
                defaultValues={{}}
                state={{ data: {} }}
                methods={methods}
              />
              <ActionButton primary bold loading={loadingCompanyInformation}>
                <Text label="Save" centered dark full />
              </ActionButton>
            </Col>
          )}
        </FormHolder>
        <Col spacing="sm">
          <Text label="Details" large spacing="sm" />
          <FormHolder onSubmit={onUpdateCompanyInformation}>
            {({ methods }) => (
              <Col>
                <BaseForm
                  pages={[
                    {
                      direction: 'col',
                      elements: [
                        {
                          isRequired: true,
                          name: 'name',
                          placeholder: 'Name of organization',
                          defaultValue: '',
                          label: 'Name',
                          full: true
                        },
                        {
                          isRequired: true,
                          name: 'description',
                          placeholder: 'Description',
                          defaultValue: '',
                          label: 'Description',
                          full: true
                        },
                        {
                          isRequired: true,
                          name: 'industry',
                          placeholder: 'Industry',
                          defaultValue: '',
                          label: 'Industry',
                          full: true
                        },
                        {
                          isRequired: true,
                          name: 'website',
                          placeholder: 'Website',
                          defaultValue: '',
                          label: 'Website',
                          full: true
                        }
                      ]
                    }
                  ]}
                  defaultValues={{}}
                  state={{ data: {} }}
                  methods={methods}
                />
                <ActionButton primary bold loading={loadingCompanyInformation}>
                  <Text label="Save" centered dark full />
                </ActionButton>
              </Col>
            )}
          </FormHolder>
        </Col>
      </Col>
    </Layout>
  );
};

export default Profile;
